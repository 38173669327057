import React, { useContext, useEffect, useState } from 'react';
import { Button, Table, Modal } from 'antd';
import { RichText } from 'prismic-reactjs';
import { PortalAgentesFormContext, PortalAgentesContext } from 'context/context';
import { pagConfig } from 'constants/site';
import { recotizacion, recotizadores } from 'authConfig';
import './styles.scss';
import Empty from 'components/empty';
import Loading from 'components/loading';
import { exportAsExcelFile } from 'services/excelService';
import { Microservice } from "services/microservice";
import moment from 'moment';
const CryptoJS = require("crypto-js");

const formatMoney = (value)=>{
	if(value){
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	}
	return '';
}
const TableDinamic = ({ data, redirectUri, callCenter = false }) => {
	const {

		handle: { setColumnsInfo, setLoadingDownload, setDataSource },
		value: { dataFields, dataSource, loadingData, showTable, 
			loadingDownload, redirectUriCotizador, redirectValue }

	} = useContext(PortalAgentesFormContext);
	const { value: { user } } = useContext(PortalAgentesContext);
	const [redirectUrisObj, setRedirectUrisObj] = useState({});
	const [open, setOpen] = useState(false);
	const [dataModalInfoCliente, setDataModalInfoCliente] = useState({});

	const primary = data.primary;
	const columns = data.items.map((item) => ({
		title: item.name[0].text,
		dataIndex: item.key,
		key: item.key,
		defaultSortOrder: 'ascend',
		sorter: item.key === "fechaCotizacion" && { compare: (a, b) => moment(a[item.key]).unix() - moment(b[item.key]).unix() },
		render: (value, record) => {
			return (
				<>
					{item.key === 'accion' ?
						(
							<Button
								className="outline c-2"
								disabled={record.estado === 'VENCIDA'}
								onClick={() => redirectRecotizacion(record.solucion, (record.solicitud || record.cotizacion), record)}
								data-testid="Recotizar"
							>
								Recuperar
							</Button>
						) : item.key === "infoCliente" ? (
							<span className="strong-table" onClick={() => openModal(value)}>
								{value?.nombre}
							</span>
						) : item.key === "infoAgente" ? value?.name 
						: item.key === "agentComission" && value? value + '%' 
						: item.key === "netPremium" ? formatMoney(value) 
						: value
					}
				</>
			)
		}
	}));

	useEffect(() => {
    return () => {
      setDataSource([])
    };
  }, []);

	const localeConfig = {
		emptyText: <Empty message='No se encontraron resultados' />
	}

	const getCode = (solucion) => {
		switch (solucion) {
			case 'PES':
				return 'PES';
			case 'RC':
				return 'RC';
			case 'ALA AZUL':
				return 'GM';
			case 'ALA AZUL':
				return 'GMP';
			case 'AUTOS':
				return 'AUTOS';
      case 'RC COMPETITIVIDAD':
        return 'RC COMPETITIVIDAD';
			default:
				return '';
		}
	}

	const redirectRecotizacion = async (solucion, cotizacion, document) => {
		const url = redirectUrisObj[redirectUriCotizador];
		if (url.indexOf("smart") === -1 && url.indexOf("cotizadoragentes") === -1) {
			const userId = document.infoAgente && document.infoAgente.usuarioId ? document.infoAgente.usuarioId : '';
			const oficinaId = document.infoAgente && document.infoAgente.oficinaId ? document.infoAgente.oficinaId : '';
			const ramoId = document.infoAgente && document.infoAgente.ramoId ? document.infoAgente.ramoId : '';
			const email = document.infoAgente && document.infoAgente.email ? document.infoAgente.email : user&&user.email?user.email:'';
			const token = await generateToken(cotizacion.toString(), userId, oficinaId, ramoId, email);
			window.open(`${url}${token}`, '_blank');
		} else {
			const codigo = getCode(solucion);
			const parameters = `?portal=sura_hub&username=${dataFields.userId}&redirecturi=${redirectUrisObj[redirectUriCotizador]}&loginType=B2CAuth&secret=${recotizacion.secret}`;
			const message = window.open(`${recotizacion.url}${parameters}`, '_blank');
			setTimeout(() => {
				message.postMessage(JSON.stringify({ code: codigo, transactionId: cotizacion }), redirectUrisObj[redirectUriCotizador]);
			}, process.env.REACT_APP_TIMEOUT_DELAY);
		}
	}

	const generateToken = async (cotizacion, userId, oficinaId, ramoId, email) => {
		const agentData = redirectValue && redirectValue.agentData;
		const dataEncrypt = CryptoJS.AES.encrypt(JSON.stringify({
			cotizacionId: cotizacion, 
			usuarioId: dataFields && dataFields.userId ? dataFields.userId : userId, 
			email: email,
			oficinaId: oficinaId.length > 0 ? oficinaId : agentData && agentData.oficinaId, 
			ramoId: ramoId.length > 0 ? ramoId: agentData && agentData.ramoId,
			isCallCenter: callCenter,
      name: user.name
		}), recotizadores.key).toString();
		const data = { value: dataEncrypt, origen: 'externo', };
		const res = await Microservice(data, user.idToken, 'profiling');
		return res.token;
	}

	const randomUID = () => {
		return (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
	}

	const downloadFile = async () => {
		setLoadingDownload(true);
		const columnsName = {};
		data.items.forEach((item) => {
			if (item.key !== 'accion') {
				columnsName[item.key] = item.name[0].text;
			}
		});
		exportAsExcelFile(dataSource, columnsName, data.primary.title[0].text);
		setLoadingDownload(false);
	}

	const openModal = (infoCliente) => {
		setDataModalInfoCliente(infoCliente);
		setOpen(true);
	}

	const closeModal = () => {
		setDataModalInfoCliente({});
		setOpen(false);
	}

	useEffect(() => {
	    setDataSource([]);
	}, []);

	useEffect(() => {
		if (data.items.length) {
			const newObj = {};
			data.items.forEach(item => {
				if (item.key !== 'accion') {
					newObj[item.key] = item.prop_report
				}
			});
			setColumnsInfo(newObj);
		}
		if (redirectUri.length) {
			const obj = {}
			redirectUri.split(';').forEach((x) => {
				const key = x.split(':', 1);
				obj[key] = x.split(`${key}:`)[1];
			});
			setRedirectUrisObj(obj);
		}
	}, [data.items, redirectUri]); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<section className="table__section">
			<div className="table__section-header">
				<h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--extra-bold">
					<strong>{RichText.asText(primary.title)}</strong> {`(${dataSource && dataSource.length ? dataSource.length : 0} resultados)`}
				</h3>
				{showTable && dataSource && dataSource.length ? (
					<Button
						className="outline c-2 ant-btn--tx-uppercase"
						onClick={() => downloadFile()}
						disabled={loadingDownload}
						loading={loadingDownload}
					>
						<em className="fal fa-download" />
						<span datatestId="Descargar" className="text">
							{loadingDownload ? 'Descargando...' : 'Descargar'}
						</span>
					</Button>
				) : null}
			</div>
			{showTable ?
				<>
					{dataSource && dataSource.length ?
						<>
							{!loadingData ? (
								<>
									<div className="table__section-content">
										<Table
											locale={localeConfig}
											dataSource={dataSource}
											columns={columns}
											pagination={pagConfig}
											className="table__default"
											loading={loadingData}
											scroll={{ x: 500 }}
											rowKey={(record) => `${record.solucion}${record.cotizacion}-${randomUID()}`}
											id='asd'
										/>
									</div>
									<Modal 
										title={
											<h3 className="title__ppal title__ppal--sm title__ppal--c-dr title__ppal--line title__ppal--extra-bold title__ppal--line-c-2 title__ppal--center title__ppal--column">
												<span>Información de</span>
												<strong>Cliente</strong>
											</h3>
										}  
										visible={open} 
										onCancel={() => closeModal()} 
										data-testid="modalinfocliente"
										wrapClassName="modal__default" 
										width="600px" 
										footer=""
									>
										<div className="info__cliente-content">
											<h6 className="info__cliente-title">
												Nombre:
												<strong>{dataModalInfoCliente.nombre}</strong>
											</h6>
											<h6 className="info__cliente-title">
												Número celular:
												<strong>{dataModalInfoCliente.telefono}</strong>
											</h6>
											<h6 className="info__cliente-title">
												Correo electrónico:
												<strong>{dataModalInfoCliente.email}</strong>
											</h6>
										</div>										
										<Button className="solid c-3" data-testid="btn-close" onClick={() => closeModal()}>
											Aceptar
										</Button>
									</Modal>
								</>
							) : <Loading />}
						</>
						: <Empty message={`No se han encontrado ${RichText.asText(primary.title)}`} />
					}
				</>
				: null}
		</section>
	)
}

export default TableDinamic