// @vendors
import React from 'react'
import {Button, Form, Input} from "antd";
import {useState, useContext} from "react";
import {recotizadores} from 'authConfig';
import {Microservice} from "services/microservice";
import {PortalAgentesContext, PortalAgentesFormContext} from 'context/context';

// @styles 
import './styles.scss'
import ChooseOnlyUser from 'components/choose-only-user';


export default function ContinueProcess({info, codeProduct}) {
    const [value, setValue] = useState("");
    const [loadBtn, setLoadBtn] = useState(false);
    const data = info && info.items && info.items[0] ? info.items[0] : {};
    const [openChooseOnlyUser, setOpenChooseOnlyUser] = useState(false);
    const {value: {user}} = useContext(PortalAgentesContext);
    const {value: {selectedProduct, allowedUsers}} = useContext(PortalAgentesFormContext);
    const regex = '^[0-9]*$';

    const selectedUser = async (cdUsuario) => {
        const agentValue = allowedUsers.find(x => x.cdUsuario === cdUsuario)
        const token = await generateToken(agentValue || {});
        data.linktoredirect && window.open(`${data.linktoredirect}${token}`, '_blank');
    }

    const generateToken = async (agent) => {

        const dataToEncrypt = {
            cotizacionId: value, 
            usuarioId: agent.cdUsuario, 
            email: user.email,
            oficinaId: agent.cdOficina, 
            ramoId: selectedProduct.claveProducto,
            name: user.name
        }

        let dataEncrypt;

        //Encriptación segura para compatibilidad de desencriptado en back java cotizadores web v2 java (vida grupo)
        if (String(dataToEncrypt.ramoId) === '902') {
            const {Crypto} = require('@peculiar/webcrypto');
            const crypto = new Crypto();

            // Generar un IV (nonce) aleatorio
            const iv = crypto.getRandomValues(new Uint8Array(12));

            // Convertir la clave secreta y el texto a ArrayBuffers
            const keyBuffer = new TextEncoder().encode(recotizadores.v2_key);
            const textBuffer = new TextEncoder().encode(JSON.stringify(dataToEncrypt));

            // Importar la clave
            const key = await crypto.subtle.importKey(
                'raw',
                keyBuffer,
                {name: 'AES-GCM'},
                false,
                ['encrypt']
            );

            // Encriptar el texto
            const encryptedBuffer = await crypto.subtle.encrypt(
                {
                    name: 'AES-GCM',
                    iv: iv
                },
                key,
                textBuffer
            );

            // Concatenar el IV con el texto encriptado
            const encryptedArray = new Uint8Array(iv.byteLength + encryptedBuffer.byteLength);
            encryptedArray.set(iv, 0);
            encryptedArray.set(new Uint8Array(encryptedBuffer), iv.byteLength);

            dataEncrypt = Buffer.from(encryptedArray).toString('base64');

        } else {
            const CryptoJS = require("crypto-js");
            dataEncrypt = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), recotizadores.key).toString();
        }

        const data = {value: dataEncrypt, origen: 'externo'};
        const res = await Microservice(data, user.idToken, 'profiling');
        return res.token;
    }

    const openModalUsers = () => {
        setOpenChooseOnlyUser(true);
    }

    return (
        <>
            <ChooseOnlyUser
                users={allowedUsers}
                visible={openChooseOnlyUser}
                onClose={() => setOpenChooseOnlyUser(false)}
                selectUser={(cdUsuario) => selectedUser(cdUsuario)}
            />
            <div className='process__container' id={info.idcontent}>
                <div className='process__container-content'>
                    <div className='homepage__container-title left-title'>
                        <p>{info.primary && info.primary.title ? info.primary.title : ""}</p>
                    </div>
                    <div className="process__container-content__actions">
                        <div className="process__container-content__actions-input">
                            <Form.Item label={data.labelfield ? data.labelfield : ""}
                                       className="process__container-content__actions-input-home">
                                <Input disabled={loadBtn} maxLength={13}
                                       placeholder={data.placeholderfield ? data.placeholderfield : ""}
                                       onChange={(e) => setValue(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        {data.titlebtn && (
                            <Button
                                onClick={openModalUsers}
                                loading={loadBtn}
                                className='ant-btn solid c-3 ant-btn-size-xs'
                                disabled={!value || value.length < 4 || !(new RegExp(regex).test(value))}
                            >
                                <strong>{data.titlebtn}</strong>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}