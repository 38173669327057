// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_KNOW_AUTHORITIES],
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
        validateAuthority: false
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE
    }
};

export const resetPassword = {
    url: process.env.REACT_APP_RESET_PASS
}

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: ['openid', 'profile'],
};

export const perfilamiento = {
    url: process.env.REACT_APP_PERFILAMIENTO
};

export const microservice = {
    url: process.env.REACT_APP_MICROSERVICE_VERSION
}

export const urlBase = {
    url: process.env.REACT_APP_URL_BASE
}

export const cotizacion = {
    url: process.env.REACT_APP_COTIZACION_URL,
    secret: process.env.REACT_APP_COTIZACION_SECRET
}

export const recotizacion = {
    url: process.env.REACT_APP_RECOTIZACION_URL,
    secret: process.env.REACT_APP_RECOTIZACION_SECRET
}

export const apiGraph = {
    url: process.env.REACT_APP_GRAPH_URL,
    attributes: process.env.REACT_APP_PROFILE_STANDAR_ATTRIBUTES,
    extendedAttributes: process.env.REACT_APP_PROFILE_EXTENDS_ATTRIBUTES,
    extendedAttributesClientId: process.env.REACT_APP_EXTENSION_ATTRIBUTES_CLIENT_ID
}

export const recotizadores = {
    key: process.env.REACT_APP_RECOTIZADORES_KEY,
    v2_key: process.env.REACT_APP_RECOTIZADORES_V2_KEY
}

export const callCenterOpt = {
    cdModel: process.env.REACT_APP_CDMODEL_CALL_CENTER
}

export const accountStatus = {
    cdModelAgentes: process.env.REACT_APP_CDMODEL_AGENTES,
    cdModelPromotor: process.env.REACT_APP_CDMODEL_PROMOTOR
}

export const myReports = {
    clientId:process.env.REACT_APP_TABLEAU_APP_CLIENT_ID,
    clientSecretKey:process.env.REACT_APP_TABLEAU_APP_CLIENT_SECRET_KEY,
    clientSecretId:process.env.REACT_APP_TABLEAU_APP_CLIENT_SECRET_ID
}

export const typeFormId = process.env.REACT_APP_TYPE_FORM_ID ?? ''
export const serviceUrlInsuranceAgents = process.env.REACT_APP_SERVICE_INSURANCE_AGENTS ?? ''

export const apm = {
    service: {
        url: process.env.REACT_APP_APM_SERVICE_URL ?? '',
        xapikey: process.env.REACT_APP_APM_SERVICE_XAPIKEY ?? '',
        secret: process.env.REACT_APP_APM_SERVICE_SECRET ?? '',
    },
    token: {
        url: process.env.REACT_APP_APM_TOKEN_URL ?? '',
        secret: process.env.REACT_APP_APM_TOKEN_SECRET ?? '',
    }
}